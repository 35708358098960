<template>
  <div class="list-playlist">
    <div class="row">
      <PlaylistItem class="col-lg-3" :action="false" :item="{ name: 'Khóa học của tôi', number: myVideo }" to="/thu-vien-cua-toi/chuyen-de/dang-hoc"/>
      <PlaylistItem class="col-lg-3" :action="false" :item="{ name: 'Theo dõi', number: myFollow }" to="/thu-vien-cua-toi/chuyen-de/theo-doi"/>
      <PlaylistItem v-for="(item, ind) in listData" :key="ind" :item="item" class="col-lg-3" :to="'/thu-vien-cua-toi/khoa-hoc/'+item.slug" @deleted="loadData(1)"/>
    </div>
    <!-- <NoData v-if="!listData.length" /> -->
    <Pagination class="mt-3" :total="query.total" :value="query.page" :limit="query.limit" @change="loadData" />
  </div>
</template>

<script>
import Pagination from '~/components/mlearn/pagination/index'
import PlaylistItem from '~/components/mlearn/playlist/item.vue'
import Resource from '~/common/api/resource'
const playlistResource = new Resource('library/playlist/all')
const myVideoResource = new Resource('library/topic/viewed')
const myFollowoResource = new Resource('library/topic/listFollow')
export default {
  components: {
    PlaylistItem,
    Pagination
  },
  data () {
    return {
      query: {
        limit: 12,
        page: 1,
        total: 0
      },
      myVideo: 0,
      myFollow: 0,
      listData: []
    }
  },
  created () {
    this.loadData()
    this.getTotalMyVideo()
    this.getTotalMyFollow()
  },
  methods: {
    async loadData (page = 1) {
      this.query.page = page
      const { data } = await playlistResource.list(this.query)
      this.listData = data
    },
    async getTotalMyVideo () {
      const { total } = await myVideoResource.list()
      this.myVideo = total
    },
    async getTotalMyFollow () {
      const { total } = await myFollowoResource.list()
      this.myFollow = total
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-playlist{
    > .row{
      > [class*="col-"] {
        padding-top: 18px;
        @media (max-width:576px) {
          padding-top: 14px;
        }
      }
    }
  }
</style>
