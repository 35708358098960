<template>
  <b-modal v-model="showModal" @hide="hide" id="modal-forget" size="md" hide-footer hide-header no-close-on-backdrop no-close-on-esc body-class="position-relative" modal-class="scrollbar px-0" centered>
    <img alt="logo" src="~/mlearn/icons/menu/vilearn.svg" class="logo">
    <img alt="logo" src="~/mlearn/icons/modal/close-modal.svg" class="close pointer position-absolute" @click="showModal = false">
    <div class="title">Khôi phục mật khẩu</div>
    <template v-if="!status">
      <div class="description">Bạn vui lòng nhập địa chỉ email đã dùng đăng ký tài khoản Vilearn để tiến hành khôi phục mật khẩu.</div>
      <div class="group-item">
        <div class="label">Nhập Địa chỉ email</div>
        <div>
          <input v-model="email" placeholder="Nhập Địa chỉ email" />
        </div>
      </div>
      <div class="group-item">
        <button @click="forgetPassword()" class="forget" :class="{disable:loading}">Lấy mã OTP</button>
      </div>
    </template>
    <template v-else>
      <div class="description">Mã OTP đã được gửi về địa chỉ email của bạn!</div>
      <div class="group-item">
        <div class="label">Nhập mật khẩu mới</div>
        <div class="position-relative">
          <input :type="showpassword ? 'text' : 'password'" v-model="newPassword" placeholder="Nhập mật khẩu mới" />
          <img :src="showpassword ? IconShow : IconHide" alt="View" class="action pointer position-center" @click="showpassword = !showpassword">
        </div>
      </div>
      <div class="group-item">
        <div class="label">Nhập lại mật khẩu mới</div>
        <div class="position-relative">
          <input :type="shownewpassword ? 'text' : 'password'" v-model="rePassword" placeholder="Nhập lại mật khẩu mới" />
          <img :src="shownewpassword ? IconShow : IconHide" alt="View" class="action pointer position-center" @click="shownewpassword = !shownewpassword">
        </div>
      </div>
      <div class="group-item">
        <div class="label">Nhập mã OTP</div>
        <div>
          <input v-model="otp" placeholder="Nhập mã OTP" />
        </div>
      </div>
      <div class="group-item">
        <button @click="resetPassword()" class="forget" :class="{disable:loading}">Đặt lại mật khẩu</button>
      </div>
    </template>
  </b-modal>
</template>
<script type="text/javascript">
import { forgetPassword } from '~/common/api/auth'
import { changePassApp } from '~/common/api/forgot'
import IconShow from '~/assets/mlearn/icons/change-pass/show.svg'
import IconHide from '~/assets/mlearn/icons/change-pass/hide.svg'
export default {
  data () {
    return {
      IconShow,
      IconHide,
      showModal: false,
      email: null,
      loading: false,
      newPassword: '',
      rePassword: '',
      otp: '',
      status: '',
      showpassword: false,
      shownewpassword: false
    }
  },
  methods: {
    forgetPassword () {
      if (this.loading) { return }
      if (!this.email) {
        this.$notify({
          message: 'Chưa nhập địa chỉ email',
          type: 'error'
        })
        return
      }
      if (!this.$helper.validEmail(this.email)) {
        this.$notify({
          message: 'Địa chỉ Email không đúng định dạng',
          type: 'error'
        })
        return
      }
      this.loading = true
      forgetPassword(this.email)
        .then(() => {
          this.status = 'success'
        })
        .catch((err) => {
          this.$notify({
            message: err.response.data.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    hide () {
      this.status = ''
      this.email = null
      this.newPassword = ''
      this.rePassword = ''
      this.otp = ''
      this.showpassword = false
      this.shownewpassword = false
    },
    resetPassword () {
      this.loading = true
      const data = {
        email: this.email,
        newPassword: this.newPassword,
        rePassword: this.rePassword,
        otp: this.otp
      }
      changePassApp(data)
        .then(() => {
          this.$bvModal.hide('modal-forget')
          this.$bvModal.show('modal-login')
          return this.$notify({
            title: 'Thành công',
            message: 'Đặt lại mật khẩu thành công.',
            type: 'success'
          })
        })
        .catch((err) => {
          this.$notify({
            message: err.response.data.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss">
  #modal-forget{
    &___BV_modal_outer_{
      z-index: 9999 !important;
      .modal-dialog{
        max-width: 456px;
        .modal-content{
          .modal-body{
            padding:40px 44px 30px;
            .close{
              top: 24px;
              right: 24px;
            }
            .title{
              padding-top: 24px;
              font-weight: 600;
              font-size: 20px;
            }
            .description{
              font-weight: 500;
              font-size: 11px;
              color: #65676B;
            }
            .group-item{
              padding-top: 20px;
              .label{
                // padding-left: 16px;
                margin-bottom: 5px;
                font-size: 11px;
                span{
                  color: var(--danger);
                }
              }
              input{
                background: #F2F2F2;
                border: 0.5px solid #E5E5E5 !important;
                border-radius: 6px;
                padding:14px 16px;
                font-size: 15px;
                width: 100%;
                &::placeholder{
                  color: #808080;
                }
              }
              button{
                border-radius: 6px;
                padding:10px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width:100%;
                &.forget{
                  font-weight:bold;
                  font-size: 15px;
                  background: var(--primary);
                  color: var(--white);
                }
              }
              .action {
                right: 16px;
              }
              .position-center{
                left: 95%;
              }
            }
          }
        }
      }
    }
  }
</style>
